export default [
  {
    path: '/devices',
    name: 'devices-index',
    component: () => import('@/views/catalog/devices/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.devices',
      breadcrumb: [
        {
          text: 'admin.navigation.devices',
          active: true,
        },
      ],
      resource: 'devices',
      action: 'index',
    },
  },
  {
    path: '/devices/create',
    name: 'devices-store',
    component: () => import('@/views/catalog/devices/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.devices',
      breadcrumb: [
        {
          text: 'admin.navigation.devices',
          to: { name: 'devices-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'devices',
      action: 'store',
    },
  },
  {
    path: '/devices/edit/:id',
    name: 'devices-update',
    component: () => import('@/views/catalog/devices/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.devices',
      breadcrumb: [
        {
          text: 'admin.navigation.devices',
          to: { name: 'devices-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'devices',
      action: 'update',
    },
  },

  {
    path: '/models',
    name: 'models-index',
    component: () => import('@/views/catalog/models/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.models',
      breadcrumb: [
        {
          text: 'admin.navigation.models',
          active: true,
        },
      ],
      resource: 'models',
      action: 'index',
    },
  },
  {
    path: '/models/create',
    name: 'models-store',
    component: () => import('@/views/catalog/models/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.models',
      breadcrumb: [
        {
          text: 'admin.navigation.models',
          to: { name: 'models-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'models',
      action: 'store',
    },
  },
  {
    path: '/models/edit/:id',
    name: 'models-update',
    component: () => import('@/views/catalog/models/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.models',
      breadcrumb: [
        {
          text: 'admin.navigation.models',
          to: { name: 'models-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'models',
      action: 'update',
    },
  },

  {
    path: '/problems',
    name: 'problems-index',
    component: () => import('@/views/catalog/problems/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.problems',
      breadcrumb: [
        {
          text: 'admin.navigation.problems',
          active: true,
        },
      ],
      resource: 'problems',
      action: 'index',
    },
  },
  {
    path: '/problems/create',
    name: 'problems-store',
    component: () => import('@/views/catalog/problems/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.problems',
      breadcrumb: [
        {
          text: 'admin.navigation.problems',
          to: { name: 'problems-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'problems',
      action: 'store',
    },
  },
  {
    path: '/problems/edit/:id',
    name: 'problems-update',
    component: () => import('@/views/catalog/problems/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.problems',
      breadcrumb: [
        {
          text: 'admin.navigation.problems',
          to: { name: 'problems-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'problems',
      action: 'update',
    },
  },

  {
    path: '/tips',
    name: 'tips-index',
    component: () => import('@/views/catalog/tips/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.tips',
      breadcrumb: [
        {
          text: 'admin.navigation.tips',
          active: true,
        },
      ],
      resource: 'tips',
      action: 'index',
    },
  },
  {
    path: '/tips/create',
    name: 'tips-store',
    component: () => import('@/views/catalog/tips/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.tips',
      breadcrumb: [
        {
          text: 'admin.navigation.tips',
          to: { name: 'tips-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'tips',
      action: 'store',
    },
  },
  {
    path: '/tips/edit/:id',
    name: 'tips-update',
    component: () => import('@/views/catalog/tips/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.tips',
      breadcrumb: [
        {
          text: 'admin.navigation.tips',
          to: { name: 'tips-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'tips',
      action: 'update',
    },
  },
]
