export default [
  {
    path: '/reviews',
    name: 'reviews-index',
    component: () => import('@/views/reviews/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.reviews',
      breadcrumb: [
        {
          text: 'admin.navigation.reviews',
          active: true,
        },
      ],
      resource: 'reviews',
      action: 'index',
    },
  },
  {
    path: '/reviews/create',
    name: 'reviews-store',
    component: () => import('@/views/reviews/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.reviews',
      breadcrumb: [
        {
          text: 'admin.navigation.reviews',
          to: { name: 'reviews-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'reviews',
      action: 'store',
    },
  },
  {
    path: '/reviews/edit/:id',
    name: 'reviews-update',
    component: () => import('@/views/reviews/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.reviews',
      breadcrumb: [
        {
          text: 'admin.navigation.reviews',
          to: { name: 'reviews-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'reviews',
      action: 'update',
    },
  },
]
