export default [
  {
    path: '/orders',
    name: 'orders-index',
    component: () => import('@/views/orders/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.orders',
      breadcrumb: [
        {
          text: 'admin.navigation.orders',
          active: true,
        },
      ],
      resource: 'orders',
      action: 'index',
    },
  },
]
