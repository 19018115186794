export default [
  {
    path: '/fm',
    name: 'file-manager',
    component: () => import('@/views/fm/List'),
    meta: {
      pageTitle: 'admin.navigation.file-manager',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.file-manager',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/system/settings/List'),
    meta: {
      pageTitle: 'admin.navigation.settings',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.settings',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import('@/views/system/languages/List'),
    meta: {
      pageTitle: 'admin.navigation.languages',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.languages',
          active: true,
        },
      ],
      resource: 'languages',
      action: 'index',
    },
  },
  {
    path: '/translations',
    name: 'translations',
    component: () => import('@/views/system/translations/List'),
    meta: {
      pageTitle: 'admin.navigation.translations',
      breadcrumb: [
        {
          text: 'admin.navigation.system',
        },
        {
          text: 'admin.navigation.translations',
          active: true,
        },
      ],
      resource: 'translations',
      action: 'index',
    },
  },

  {
    path: '/shops',
    name: 'shops-index',
    component: () => import('@/views/shops/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.shops',
      breadcrumb: [
        {
          text: 'admin.navigation.shops',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'index',
    },
  },
  {
    path: '/shops/create',
    name: 'shops-store',
    component: () => import('@/views/shops/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.shops',
      breadcrumb: [
        {
          text: 'admin.navigation.shops',
          to: { name: 'shops-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'store',
    },
  },
  {
    path: '/shops/edit/:id',
    name: 'shops-update',
    component: () => import('@/views/shops/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.shops',
      breadcrumb: [
        {
          text: 'admin.navigation.shops',
          to: { name: 'shops-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'update',
    },
  },
]
