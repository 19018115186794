export default [
  {
    path: '/extensions',
    name: 'extensions-index',
    component: () => import('@/views/extensions/List.vue'),
    meta: {
      pageTitle: 'admin.navigation.extensions',
      breadcrumb: [
        {
          text: 'admin.navigation.extensions',
          active: true,
        },
      ],
      resource: 'extensions',
      action: 'index',
    },
  },
  {
    path: '/extensions/create',
    name: 'extensions-store',
    component: () => import('@/views/extensions/Create.vue'),
    meta: {
      pageTitle: 'admin.navigation.extensions',
      breadcrumb: [
        {
          text: 'admin.navigation.extensions',
          to: { name: 'extensions-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'extensions',
      action: 'store',
    },
  },
  {
    path: '/extensions/edit/:id',
    name: 'extensions-update',
    component: () => import('@/views/extensions/Edit.vue'),
    meta: {
      pageTitle: 'admin.navigation.extensions',
      breadcrumb: [
        {
          text: 'admin.navigation.extensions',
          to: { name: 'extensions-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'extensions',
      action: 'update',
    },
  },
]
